<template>
  <v-container>
    <br><br><br>
    <v-row>
      <v-col
        class="col-md-6 relative contact-info col-sm-12 col-xs-12 justify-center"
        cols="12"
        sm="12"
        md="6"
      >
        <h3>Contact information</h3>
        <br>
        <div class="d-flex flex-row mb-5">
          <div>
            <v-img
              width="75"
              :aspect-ratio="1"
              src="@/assets/images/icon_office-min.png"
            />
          </div>
          <div class="ma-1 pa-2 bold">
            <b>{{ $t("Need help ?") }}</b>
            <br>
            <span>{{ $t("contact_address","PO Box 16122 Collins Street West, Victoria 8007 Australia") }}</span>
            <p>
              <label>{{ $t('Email') }}: <a href="info@planete-market.eu">{{ $t("contact_email") }}</a> </label>
            </p>
          </div>
        </div>
      </v-col>
      <v-col
        class="justify-content-center"
        cols="12"
        sm="12"
        md="6"
      >
        <h3>Submit a message</h3>
        <br><br>
        <v-form
          ref="contactusForm"
          v-model="valid"
          lazy-validation
          @submit.prevent="onSubmit"
        >
          <div>
            <div v-if="$store.state.login">
              <label>{{ $t('Nom') }}: {{ $store.state.user.name }}</label>
              <v-input
                v-if="$store.state.user"
                v-model="name"
                type="hidden"
                name="name"
                aria-autocomplete="off"
              />
            </div>
            <div  v-if="$store.state.login">
              <label>{{ $t('Identification Membre (Pseudo/NuméroID)') }}: {{ $store.state.user.pseudo }} </label>
              <v-input
                v-if="$store.state.user"
                v-model="pseudo"
                type="hidden"
                name="identifiant"
                aria-autocomplete="off"
              />
            </div>
            <div  v-if="$store.state.login">
              <label>{{ $t('Email') }}: {{ $store.state.user.email }}</label>
              <v-input
                v-if="$store.state.user"
                v-model="email"
                type="hidden"
                aria-autocomplete="off"
              />
            </div>
            <div  v-if="$store.state.login">
              <labe>{{ $t('Téléphone') }}: {{ $store.state.user.tel }}</labe>
              <v-input
                v-if="$store.state.user"
                v-model="tel"
                type="hidden"
                aria-autocomplete="off"
              />
            </div>
            <v-text-field
              v-if="!$store.state.login"
              v-model="name"
              outlined
              :label="$t('Nom')"
              rows="4"
              required

            />
            <v-text-field
              v-if="!$store.state.login"
              v-model="email"
              outlined
              :label="$t('Email')"
              rows="4"
              required
              row-height="30"

            />
            <v-text-field
              v-if="!$store.state.login"
              v-model="tel"
              outlined
              :label="$t('Téléphone')"
              rows="4"
              required
              row-height="30"

            />
            <v-select
              v-model="objects"
              outlined
              :items="objects"
              item-text="text"
              item-value="value"
              type="text"
              :label="$t('Objet')"
            />
            <v-checkbox
              v-model="subvention"
              input-value="false"
              :label="$t('Aide à Subvention')"
              class="d-flex demo-space-x flex-wrap"
              hide-details
            />
            <br>
            <v-textarea
              v-model="message"
              outlined
              :label="$t('Message')"
              rows="4"
              row-height="30"
              hide-details
            />
            <br>
            <v-btn
              :disabled="!valid"
              color="error"
              class="mx-0"
              type="submit"
              :loading="loading"
            >
              {{ $t('SOUMETTRE') }}
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
    <br><br>
  </v-container>
</template>

<script>
  export default {

    data () {
      return {
        objects: ['Assistance Vendeur Professionnel', 'Assistance Vendeur Particulier', 'Assistance Acheteur', 'Aide Subvention Association'],
        valid: false,
        tel: null,
        name: null,
        email: null,
        pseudo: null,
        message: null,
        loading: false

      }
    },
    created () {
      if(this.$store.state.login) {
        this.tel = this.$store.state.user.tel
        this.email = this.$store.state.user.email
        this.name = this.$store.state.user.name
        this.pseudo = this.$store.state.user.pseudo
      }

    },
    methods: {
      onSubmit () {
        this.loading = true
        axios({
          method: 'post',
          url: '/contact-submit',
          data: { name: this.name, pseudo: this.pseudo, email: this.email, tel: this.tel, message: this.message, object: this.object },
          // eslint-disable-next-line no-unused-vars
        })
          .catch(error => {
            this.loading = false
            // TODO: Next Update - Show notification
            console.error('Oops, Unable to submit!')
            console.log('error :>> ', error.response || '')
            // this.errorMessages = error.response.data.error
          })
      },

    },

  }
</script>
<style lang="scss" scoped>

</style>
